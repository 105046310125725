<template>
  <v-card class="mx-auto mt-5" elevation="4" max-width="400">
    <v-card-title class="justify-center">Sign In</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              label="E-mail"
              placeholder="E-mail"
              solo
              required
              :rules="req"
              v-on:keyup.enter="submit"
              data-cy="email-input"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Password"
              type="password"
              solo
              required
              v-on:keyup.enter="submit"
              :rules="req"
              data-cy="password-input"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              color="primary"
              style="width: 100%"
              @click="submit"
              :loading="loading"
              data-cy="submit-btn"
            >
              Sign In
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="forgot">
              <router-link to="/reset-password">Forgot Password?</router-link>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" style="width: 100%" to="/signup">
              Sign Up
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<style scoped>
.v-card__title {
  text-align: center;
  width: 100%;
}

.v-application a {
  text-align: center;
  width: 100%;
  text-decoration: none;
  color: #22abe1;
  display: flex;
  justify-content: center;
}
</style>

<script>
import hasuraService from "@/services/hasura.service.js";

export default {
  name: "Login",
  mixins: [hasuraService],
  methods: {
    submit: async function () {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let result = await this.login(this.email, this.password);
        if (result.ok) {
          localStorage.setItem("token", result.jwt);
          setTimeout(() => {
            // we are using href to redirect to the next route
            // because there is a race condition
            window.location.href = this.$store.state.next_route || "/seminar";
          }, 50);
        } else {
          this.$store.commit("set_snackbar", result.reason);
          this.loading = false;
        }
      }
    },
  },
  data: () => {
    return {
      loading: false,
      email: "",
      password: "",
      valid: true,
      req: [(v) => !!v || "Field is required"],
    };
  },
};
</script>
